import { graphql } from "gatsby"
import React, { useState } from "react"
import { getUser, isLoggedIn } from "../../services/auth"

import Layout from "../Page"
import Footer from "../../components/ContentBuilder/Footer"
import SwiperList from "../../components/ContentBuilder/SwiperList"
import Header from "../../components/ContentBuilder/Header"
import CustomDesign from "../../components/ContentBuilder/SwiperList/CustomDesign"
import MyBoards from "../../components/MyBoards"
import MyQuotes from "../../components/MyQuotes"
import RecentlyViewed from "../../components/RecentlyViewed"
import SwiperBoardItems from "../../components/ContentBuilder/SwiperList/SwiperBoardItems"
import SwiperQuotes from "../../components/ContentBuilder/SwiperList/SwiperQuotes"
import RecentViews from "../../components/RecentViews"
import CreateOnlyBoardModal from "../../components/ContentBuilder/Modal/CreateOnlyBoardModal"

const MyBoardsPagePage = ({ data: { silverStripeDataObject } }) => {
  const {
    bannerTitle,
    bannerSubTitle,
    bannerImageURL,
  } = silverStripeDataObject.Page
   setTimeout(function(){
        if (!isLoggedIn()) {
            if(typeof window !== 'undefined'){            
                window.location.href = "/login/";
            }
        }
    }, 500); 
  var Member = getUser()
  var nameText = '';
  var firstName = Member.FirstName
  if(firstName){
      nameText += firstName.charAt(0);
  }
  var lastName = Member.Surname
  if(lastName){
      nameText += lastName.charAt(0);
  }
  var boards = []
  if (typeof Member.Boards !== "undefined" && Member.Boards !== null) {
    boards = Object.keys(Member.Boards).map(key => Member.Boards[key])
    boards.sort((a, b) => {
        return a.ID < b.ID ? 1 : -1
    })
  }
  
  const [showAddBoard, setShowAddBoard] = useState(false)
  return(
    <Layout>
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              My Boards
            </li>
          </ol>
        </nav>

        <div className="account-avt-wrap">
          <div className="account-avt">
            <a href="/account/my-boards/" className="account-avt-inner bg-black">
              <img src="/img/boards-grid-icon.svg" className="grid-ico"/>
            </a>
          </div>
          <p className="account-avt-name d-none d-lg-block">
            {Member.FirstName} {Member.Surname}
          </p>
        </div>

        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h2 className="product-name-title d-flex align-items-center text-uppercase">
                My Boards   
                <a href="#" onClick={() => setShowAddBoard(true)}>
                <svg className="ml-4 cursor-pointer" width="43px" height="43px">
                  <use xlinkHref="/img/icons.svg#icon-circle-plus"></use>
                </svg>
                </a>
              </h2>
            </div>
          </div>
        </div>
        <SwiperBoardItems title="" navClass="board" boards={boards} />
        <RecentViews/>
      </div>
      <CreateOnlyBoardModal
        show={showAddBoard}
        handleClose={() => setShowAddBoard(false)}
      />
    </Layout>
  )
}
export default MyBoardsPagePage
export const pageQuery = graphql`
  query($link: String!) {
    silverStripeDataObject(link: { eq: $link }) {
      Page {
        bannerTitle
        bannerSubTitle
        bannerImageURL
      }
    }
  }
`
