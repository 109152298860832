import React from "react"
import PropTypes from "prop-types"

const SwiperBoardItems = ({ title, navClass, boards }) => {
  var totalBoards = boards.length
  if (totalBoards > 0) {
    var i = 0
    for (; boards[i]; ) {
      var board = boards[i]
      if (
        typeof board !== "undefined" &&
        board !== "" &&
        board.Items !== null
      ) {
        if (typeof board.Items !== "undefined") {
          var itemsJSON = JSON.parse(board.Items)
          if (itemsJSON.length !== 0) {
            var items = Object.keys(itemsJSON).map(key => itemsJSON[key])
            board.totalPins = items.length
            if (typeof items[0] !== "undefined") {
              board.Item1 = items[0]
            }
            if (typeof items[1] !== "undefined") {
              board.Item2 = items[1]
            }
            if (typeof items[2] !== "undefined") {
              board.Item3 = items[2]
            }
          }
        }
      } else {
        board.totalPins = 0
      }
      i++
    }
  }

  return (
    <div className="row explore-wrap pb-3 mt-0">
      {boards.map((board, idx) => (
        <div
          className="col-12 col-md-6 col-xl-4"
          key={`swiper-board-${board.ID}`}
        >
          <a
            href={`/account/board?id=${board.ID}`}
            className={`product-items mb-3${!board.Item1 ? " no-image" : ""}`}
          >
            <div className="board-item">
              <div className="board-item-left">
                {board.Item1 && (
                  <img src={board.Item1.Image} alt={board.Item1.Title}></img>
                )}
                <div className="board-item-left-txt">
                  <h3>{board.Name}</h3>
                  <span>{board.totalPins} PINS</span>
                </div>
              </div>
              <div className="board-item-right">
                {board.Item2 && (
                  <div className="board-item-right-img">
                    <img src={board.Item2.Image} alt={board.Item2.Title}></img>
                  </div>
                )}
                {board.Item3 && (
                  <div className="board-item-right-img">
                    <img src={board.Item3.Image} alt={board.Item3.Title}></img>
                  </div>
                )}
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  )
}

SwiperBoardItems.propTypes = {
  navClass: PropTypes.string.isRequired,
}

export default SwiperBoardItems
